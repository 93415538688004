import { defineMessages } from 'react-intl';
import { Product } from 'contracts';

export default {
  pages: {
    layout: {
      topProfile: {
        ...defineMessages({
          model: {
            id: 'pages.layout.topProfile.model',
            defaultMessage: 'Model',
          },
          exclusive: {
            id: 'pages.layout.topProfile.exclusive',
            defaultMessage: 'Exclusive',
          },
        }),
        account: {
          [Product.LiveJasmin]: defineMessages({
            title: {
              id: 'pages.layout.topProfile.account.livejasmin.title',
              defaultMessage: 'Studio Account',
            },
          }),
          [Product.Oranum]: defineMessages({
            title: {
              id: 'pages.layout.topProfile.account.oranum.title',
              defaultMessage: 'Agency Account',
            },
          }),
        },
        upload: {
          ...defineMessages({
            label: {
              id: 'pages.layout.topProfile.upload.label',
              defaultMessage: 'Upload',
            },
          }),
          tooltip: {
            ...defineMessages({
              title: {
                id: 'pages.layout.topProfile.upload.tooltip.title',
                defaultMessage: 'Upload Profile Photo',
              },
              content: {
                id: 'pages.layout.topProfile.upload.tooltip.content',
                defaultMessage: 'Increase your visibility and performance with a Profile Photo.',
              },
              link: {
                id: 'pages.layout.topProfile.upload.tooltip.link',
                defaultMessage: 'Upload Now',
              },
            }),
          },
        },
        pending: {
          tooltip: {
            ...defineMessages({
              title: {
                id: 'pages.layout.topProfile.pending.tooltip.title',
                defaultMessage: 'Waiting for approval',
              },
              content: {
                id: 'pages.layout.topProfile.pending.tooltip.content',
                defaultMessage: 'We are currently reviewing your Profile Photo and will approve it soon.',
              },
              link: {
                id: 'pages.layout.topProfile.pending.tooltip.link',
                defaultMessage: 'See Profile Photos',
              },
            }),
          },
        },
      },
    },
  },
};
