import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      topProfile: {
        status: {
          ...defineMessages({
            active: {
              id: 'pages.layout.topProfile.status.active',
              defaultMessage: 'Active',
            },
            inactive: {
              id: 'pages.layout.topProfile.status.inactive',
              defaultMessage: 'Inactive',
            },
            closed: {
              id: 'pages.layout.topProfile.status.closed',
              defaultMessage: 'Closed',
            },
            missingData: {
              id: 'pages.layout.topProfile.status.missingData',
              defaultMessage: 'Missing Data',
            },
            pending: {
              id: 'pages.layout.topProfile.status.pending',
              defaultMessage: 'Pending',
            },
            rejected: {
              id: 'pages.layout.topProfile.status.rejected',
              defaultMessage: 'Rejected',
            },
            suspending: {
              id: 'pages.layout.topProfile.status.suspending',
              defaultMessage: 'Suspending',
            },
          }),
        },
      },
    },
  },
};
