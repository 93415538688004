import React from 'react';
import toastService from 'services/toast';
import type { ToastAPI } from 'contracts';
import ToastComponent from 'components/toast';

const Toast: React.FunctionComponent<Record<string, never>> = () => {
  const toastRef = React.useRef<ToastAPI>(null);

  React.useEffect(() => {
    const onToastChange = toastService.onChange$.subscribe((notification) => {
      if (!toastRef.current) return;

      switch (notification.action) {
        case 'dispatch':
          toastRef.current.dispatch(notification.toast);
          break;

        case 'remove':
          toastRef.current.remove(notification.id);
          break;

        case 'clear':
          toastRef.current.clear();
          break;

        default:
          break;
      }
    });

    return (): void => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      toastRef.current?.clear();
      onToastChange.unsubscribe();
    };
  }, []);

  return <ToastComponent ref={toastRef} />;
};

export default Toast;
