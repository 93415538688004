import React from 'react';
import { map } from 'rxjs';
import { useIntl } from 'react-intl';
import { link } from 'services/navigation';
import application from 'services/application';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import account from 'store/account';
import user from 'store/user';
import performer from 'store/performer';
import Html from 'components/html';
import { IconList } from 'components/icon';
import Link from 'components/link';
import Avatar from 'components/avatar';
import Shimmer from 'components/shimmer';
import CertifiedStudio from 'assets/badges/certified-studio.svg';

import ModelAvatar from './model-avatar';
import Status from './status';
import messages from './top-profile.i18n';
import { testId as testIds } from './top-profile.settings';
import styles from './top-profile.module.scss';

const TopProfile: React.FunctionComponent<unknown> = () => {
  const { formatMessage } = useIntl();
  const [accountStore] = useReadonlyObservable(account.onChange$, account.data);
  const [performerStore, performerStoreResult] = useReadonlyObservable(
    {
      source$: performer.onChange$,
      loading$: performer.onMetaChange$.pipe(map((meta) => meta.loading)),
    },
    performer.data
  );
  const [userViewType] = useReadonlyObservable(
    user.onViewTypeChange$.pipe(map(({ viewType }) => viewType)),
    user.data?.viewType
  );

  const untranslatedBroadcaster = 'Broadcaster';

  const renderAvatar = React.useCallback(
    (loading: boolean) => {
      if (user.isStudioView()) {
        if (accountStore?.flags?.studioCertified) {
          return (
            <Link href={link.statisticsDashboard()} className={['rounded-circle mb-2', styles.certifiedBadge]}>
              <CertifiedStudio />
            </Link>
          );
        }

        return (
          <Avatar
            className="mb-2"
            testId={testIds.avatar}
            icon={IconList.groupStudioSolid}
            size={110}
            iconSize={72}
            variant="dark"
          />
        );
      }

      return <ModelAvatar loading={loading} />;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userViewType, accountStore?.flags?.studioCertified]
  );

  const renderDisplayName = React.useCallback(
    (testId: string, cssClass: string | Array<string>, name: string | undefined) => {
      return (
        <Shimmer theme="light" loading={!name} style={{ minWidth: 100 }}>
          <Html.div typography="title1" fontWeight="bold" testId={testId} className={cssClass}>
            {name || '&nbsp;'}
          </Html.div>
        </Shimmer>
      );
    },
    []
  );

  return (
    <Html.div testId={testIds.topProfile} className={styles.topProfile}>
      {renderAvatar(!performerStoreResult.called || performerStoreResult.loading)}
      {user.isStudioView() && (
        <React.Fragment>
          {renderDisplayName(testIds.studioDisplayName, [styles.studioName, 'mt-0'], accountStore?.personFirstName)}
          {accountStore?.personFirstName && (
            <Html.div
              className={styles.studioAccount}
              typography="caption"
              fontWeight="bold"
              testId={testIds.studioAccount}
            >
              {formatMessage(messages.pages.layout.topProfile.account[application.current].title)}
            </Html.div>
          )}
        </React.Fragment>
      )}
      {user.isModelView() && application.LiveJasmin && performerStore?.flags?.exclusiveBadgeStatus && (
        <Html.div
          className={['mb-1', styles.exclusive]}
          typography="caption"
          fontWeight="bold"
          testId={testIds.exclusive}
        >
          {formatMessage(messages.pages.layout.topProfile.exclusive)}{' '}
          <Html.span fontWeight="light">{formatMessage(messages.pages.layout.topProfile.model)}</Html.span>
        </Html.div>
      )}
      {user.isModelView() && (
        <React.Fragment>
          {renderDisplayName(testIds.displayName, styles.modelName, performerStore?.displayName)}
          {application.LiveJasmin && performerStore?.category && (
            <Link
              testId={testIds.category}
              href={performer.topCategoryWikiLink}
              target="_blank"
              className={[styles.category, 'mb-2']}
              typography="caption"
              fontWeight="bold"
            >
              {performerStore.category.name}
            </Link>
          )}
          {application.Oranum && <Html.div className={styles.category}>{untranslatedBroadcaster}</Html.div>}
          {performerStore?.status && !performerStoreResult.loading && (
            <Status value={performerStore.status} testId={testIds.status} />
          )}
        </React.Fragment>
      )}
    </Html.div>
  );
};

export { TopProfile };
export default TopProfile;
