import React from 'react';
import { useIntl } from 'react-intl';
import { link } from 'services/navigation';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import performer from 'store/performer';
import performerProfilePictures from 'store/performer-profile-pictures';
import Html from 'components/html';
import Avatar from 'components/avatar';
import TooltipDrawer from 'components/tooltip-drawer';
import Icon, { IconList } from 'components/icon';
import Link from 'components/link';
import Shimmer from 'components/shimmer';

import messages from './model-avatar.i18n';
import { testId } from './model-avatar.settings';
import styles from './model-avatar.module.scss';

interface ModelAvatarElement {
  loading: boolean;
}

const ModelAvatar: React.FunctionComponent<ModelAvatarElement> = (props) => {
  const { loading } = props;
  const { formatMessage } = useIntl();

  useReadonlyObservable(performerProfilePictures.onChange$, performerProfilePictures.data);

  const pictureApproved = performerProfilePictures.isSelectedPictureApproved();
  const pictureUncropped =
    performerProfilePictures.hasPictures() && !performerProfilePictures.hasSelectedPicturePortraitCrop();
  const pictureUpload = !performerProfilePictures.hasPictures() && !performer.hasClosedStatus();
  const pictureWaitingApproval = !pictureUncropped && performerProfilePictures.isSelectedPictureWaitingApproval();

  return (
    <Html.div className={['mb-2', styles.modelAvatar]} testId={testId.modelAvatar}>
      <Shimmer theme="light" loading={loading} style={{ borderRadius: '100%' }}>
        <Avatar
          testId={testId.avatar}
          variant="dark"
          src={performerProfilePictures.selectedPictureUrl()}
          size={110}
          iconSize={72}
          blur={pictureWaitingApproval}
          icon={IconList.profileSolid}
          className={[
            styles.avatar,
            pictureWaitingApproval && styles.avatarPending,
            pictureUpload && styles.avatarWarning,
          ]}
        />
      </Shimmer>
      {!loading && performerProfilePictures.ready && (
        <React.Fragment>
          {pictureApproved && (
            <Link
              className={['rounded-circle', styles.approved]}
              testId={testId.approved}
              href={link.profile({ page: 'profile-picture' })}
            >
              {' '}
            </Link>
          )}
          {pictureWaitingApproval && (
            <Html.div className={styles.pending} testId={testId.pending}>
              <TooltipDrawer
                className={styles.modelAvatarTooltip}
                content={
                  <React.Fragment>
                    <Html.p className={styles.tooltipTitle} testId={testId.pendingTooltipTitle}>
                      {formatMessage(messages.pages.layout.modelAvatar.pending.tooltip.title)}
                    </Html.p>
                    <Html.p className={styles.tooltipContent} testId={testId.pendingTooltipContent}>
                      {formatMessage(messages.pages.layout.modelAvatar.pending.tooltip.content)}
                    </Html.p>
                    <Link
                      className={styles.tooltipLink}
                      href={link.profile({ page: 'profile-picture' })}
                      testId={testId.pendingTooltipLink}
                    >
                      {formatMessage(messages.pages.layout.modelAvatar.pending.tooltip.link)}
                    </Link>
                  </React.Fragment>
                }
              >
                <Link
                  href={link.profile({ page: 'profile-picture' })}
                  className={styles.pendingContent}
                  testId={testId.pendingLink}
                >
                  <Icon name={IconList.clockSolid} className={styles.pendingIcon} size={28} />
                </Link>
              </TooltipDrawer>
            </Html.div>
          )}
          {pictureUpload && (
            <Html.div className={styles.upload} testId={testId.upload}>
              <TooltipDrawer
                className={styles.modelAvatarTooltip}
                content={
                  <React.Fragment>
                    <Html.p className={styles.tooltipTitle} testId={testId.uploadTooltipTitle}>
                      {formatMessage(messages.pages.layout.modelAvatar.upload.tooltip.title)}
                    </Html.p>
                    <Html.p className={styles.tooltipContent} testId={testId.uploadTooltipContent}>
                      {formatMessage(messages.pages.layout.modelAvatar.upload.tooltip.content)}
                    </Html.p>
                    <Link
                      className={styles.tooltipLink}
                      href={link.profile({ page: 'profile-picture' })}
                      testId={testId.uploadTooltipLink}
                    >
                      {formatMessage(messages.pages.layout.modelAvatar.upload.tooltip.link)}
                    </Link>
                  </React.Fragment>
                }
              >
                <Link
                  href={link.profile({ page: 'profile-picture' })}
                  className={styles.uploadContent}
                  testId={testId.uploadLink}
                >
                  <Icon name={IconList.warningCircularSolid} className={styles.uploadWarningIcon} size={28} />
                  {formatMessage(messages.pages.layout.modelAvatar.upload.label)}
                </Link>
              </TooltipDrawer>
            </Html.div>
          )}
          {pictureUncropped && (
            <Html.div className={styles.uncropped} testId={testId.uncropped}>
              <TooltipDrawer
                className={styles.modelAvatarTooltip}
                content={
                  <React.Fragment>
                    <Html.p className={styles.tooltipTitle} testId={testId.uncroppedTooltipTitle}>
                      {formatMessage(messages.pages.layout.modelAvatar.uncropped.tooltip.title)}
                    </Html.p>
                    <Html.p className={styles.tooltipContent} testId={testId.uncroppedTooltipContent}>
                      {formatMessage(messages.pages.layout.modelAvatar.uncropped.tooltip.content)}
                    </Html.p>
                    <Link
                      className={styles.tooltipLink}
                      href={link.profile({ page: 'profile-picture' })}
                      testId={testId.uncroppedTooltipLink}
                    >
                      {formatMessage(messages.pages.layout.modelAvatar.uncropped.tooltip.link)}
                    </Link>
                  </React.Fragment>
                }
              >
                <Link
                  href={link.profile({ page: 'profile-picture' })}
                  className={styles.uncroppedContent}
                  testId={testId.uncroppedLink}
                >
                  <Icon name={IconList.warningCircularSolid} className={styles.uncroppedWarningIcon} size={28} />
                </Link>
              </TooltipDrawer>
            </Html.div>
          )}
        </React.Fragment>
      )}
    </Html.div>
  );
};

export type { ModelAvatarElement };
export { ModelAvatar };
export default ModelAvatar;
