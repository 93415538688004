import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import { IconList } from 'components/icon';
import type { NavigationMenuItem } from 'contracts';

import messages from '../loyalfans.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  name: id,
  label: messages.pages.loyalfans.navigationMenu.label,
  icon: IconList.logosLoyalfans,
  weight: 29,
  link: () => link[id](),
  visibility$: authorization.getAuthorization$(id),
};

export default menu;
