import React from 'react';
import { matchRoutes } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import { combineLatest, map } from 'rxjs';
import memoizee from 'memoizee';
import menu from 'services/navigation/menu';
import location from 'services/routing/location';
import routes from 'services/routing/routes';
import application from 'services/application';
import { onHistoryChange$ } from 'services/routing/events';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import Html from 'components/html';
import tree from 'utils/tree';
import toArray from 'utils/to-array';
import is from 'utils/is';
import type { NavigationMenuItem } from 'contracts';

import Item from './item';
import TopProfile from './top-profile';
import { testId } from './navigation-menu.settings';
import styles from './navigation-menu.module.scss';

const itemsToShimmer = 10;

type Authorization = Map<NavigationMenuItem['visibility$'], boolean>;

const getRouteMatches = (path: string): Array<string> | undefined => {
  if (is.nullish(routes) || is.nullish(path)) return undefined;

  const matches = matchRoutes(routes as Array<RouteObject>, path);

  return is.nullish(matches) ? undefined : matches.map(({ pathname }) => pathname);
};

const pickAllHierarchy = memoizee(tree.pickAllHierarchy);

const NavigationMenu: React.FunctionComponent<unknown> = () => {
  const [showNavigationMenu] = useReadonlyObservable<boolean>(
    application.container.onNavigationMenuChange$,
    application.container.data.showNavigationMenu
  );
  const [activeMenu, setActiveMenu] = React.useState<Array<NavigationMenuItem> | undefined>(undefined);
  const [authorization] = useReadonlyObservable(
    combineLatest([
      ...tree
        .pickAll(menu, (node) => !is.nullish(node.visibility$))!
        .map(({ name, visibility$ }) => visibility$!.pipe(map((status) => ({ name, status, visibility$ })))),
    ]).pipe(
      map(
        (
          auth: Array<{ name: string; status: boolean | undefined; visibility$: NavigationMenuItem['visibility$'] }>
        ) => {
          if (auth.some(({ status }) => is.nullish(status))) {
            return undefined;
          }

          const mapper = new Map<NavigationMenuItem['visibility$'], boolean>();

          auth.forEach(({ visibility$, status }) => {
            mapper.set(visibility$, status!);
          });

          return mapper;
        }
      )
    ),
    undefined as Authorization | undefined
  );

  React.useEffect(() => {
    const subscription = onHistoryChange$.subscribe(() => {
      const pathnameMatches = (getRouteMatches(window.location.pathname) ?? []).reverse();

      const menuItem = pickAllHierarchy(menu, ({ link }) => {
        return Boolean(
          toArray(link?.()).find((address) => {
            return pathnameMatches.includes(new URL(address!, location.url.origin).pathname);
          })
        );
      });

      if (!menuItem) {
        setActiveMenu(undefined);

        return;
      }

      setActiveMenu(menuItem);

      if (application.container.data.showNavigationMenu) {
        application.container.toggleNavigationMenu(false);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleOnScrimClick = React.useCallback(() => {
    application.container.toggleNavigationMenu(false);
  }, []);

  const renderMenu = (
    entries: Array<NavigationMenuItem>,
    activeNodes: Array<NavigationMenuItem> | undefined,
    shimmer = false
  ): React.ReactNode => {
    return entries.map((entry, idx) => {
      const match = activeNodes?.find((node) => node?.name === entry.name);

      return (
        <Item
          key={entry.name}
          testId={testId.navigationMenuItem}
          label={entry.label}
          link={entry?.link}
          new={entry?.new}
          icon={entry?.icon}
          notification$={entry?.notification$}
          warning$={entry?.warning$}
          visibility={authorization?.get(entry.visibility$)}
          active={!is.nullish(authorization) && match?.name === entry.name}
          locationPathname={window.location.pathname}
          shimmer={shimmer && idx < itemsToShimmer}
          loading={is.nullish(authorization)}
        >
          {Boolean(entry.children?.length) && renderMenu(entry.children!, match?.children)}
        </Item>
      );
    });
  };

  return (
    <React.Fragment>
      <Html.div className={[styles.scrim, showNavigationMenu && styles.open]} onClick={handleOnScrimClick} />
      <Html.div
        testId={testId.navigationMenu}
        className={['rounded-start-lg-1', styles.navigationMenu, showNavigationMenu && styles.open]}
      >
        <TopProfile />
        {renderMenu(menu, activeMenu, true)}
      </Html.div>
    </React.Fragment>
  );
};

export { NavigationMenu };
export default React.memo(NavigationMenu);
