import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      modelAvatar: {
        upload: {
          ...defineMessages({
            label: {
              id: 'pages.layout.modelAvatar.upload.label',
              defaultMessage: 'Upload',
            },
          }),
          tooltip: {
            ...defineMessages({
              title: {
                id: 'pages.layout.modelAvatar.upload.tooltip.title',
                defaultMessage: 'Upload Profile Photo',
              },
              content: {
                id: 'pages.layout.modelAvatar.upload.tooltip.content',
                defaultMessage: 'Increase your visibility and performance with a Profile Photo.',
              },
              link: {
                id: 'pages.layout.modelAvatar.upload.tooltip.link',
                defaultMessage: 'Upload Now',
              },
            }),
          },
        },
        pending: {
          tooltip: {
            ...defineMessages({
              title: {
                id: 'pages.layout.modelAvatar.pending.tooltip.title',
                defaultMessage: 'Waiting for approval',
              },
              content: {
                id: 'pages.layout.modelAvatar.pending.tooltip.content',
                defaultMessage: 'We are currently reviewing your Profile Photo and will approve it soon.',
              },
              link: {
                id: 'pages.layout.modelAvatar.pending.tooltip.link',
                defaultMessage: 'See Profile Photos',
              },
            }),
          },
        },
        uncropped: {
          tooltip: {
            ...defineMessages({
              title: {
                id: 'pages.layout.modelAvatar.uncropped.tooltip.title',
                defaultMessage: 'Uncropped Profile Photo',
              },
              content: {
                id: 'pages.layout.modelAvatar.uncropped.tooltip.content',
                defaultMessage: 'Your Profile Photo was not cropped for mobile.',
              },
              link: {
                id: 'pages.layout.modelAvatar.uncropped.tooltip.link',
                defaultMessage: 'See Profile Photos',
              },
            }),
          },
        },
      },
    },
  },
};
