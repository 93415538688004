import parse from 'utils/parse';

const makeTestId = (...ids: string[]): string => parse.toTestId('navigation-menu-top-profile', ...ids);

const testId = Object.freeze({
  topProfile: makeTestId(),
  studioDisplayName: makeTestId('studio-display-name'),
  studioAccount: makeTestId('studio-account'),
  exclusive: makeTestId('exclusive'),
  category: makeTestId('category'),
  displayName: makeTestId('display-name'),
  status: makeTestId('status'),
  avatar: makeTestId('avatar'),
});

export { testId };
