import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.loyalfans.seo.livejasmin.title',
      defaultMessage: 'Loyalfans',
    },
    description: {
      id: 'pages.loyalfans.seo.livejasmin.description',
      defaultMessage: 'Loyalfans',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.loyalfans.seo.oranum.title',
      defaultMessage: 'Loyalfans',
    },
    description: {
      id: 'pages.loyalfans.seo.oranum.description',
      defaultMessage: 'Loyalfans',
    },
  }),
};

export default {
  pages: {
    loyalfans: {
      seo,
      navigationMenu: {
        ...defineMessages({
          label: {
            id: 'pages.loyalfans.navigationMenu.label',
            defaultMessage: 'Loyalfans',
          },
        }),
      },
      errors: defineMessages({
        unexpectedError: {
          id: 'pages.loyalfans.error.unexpected',
          defaultMessage: 'An error occurred. Please, reload the page or try again later.',
        },
      }),
    },
  },
};
