import parse from 'utils/parse';

const makeTestId = (...ids: string[]): string => parse.toTestId('navigation-menu', ...ids);

const testId = Object.freeze({
  navigationMenu: makeTestId(),
  navigationMenuItem: makeTestId('item'),
});

export { testId };
