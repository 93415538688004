import parse from 'utils/parse';

const makeTestId = (...ids: string[]): string => parse.toTestId('navigation-menu-top-profile-model-avatar', ...ids);

const testId = Object.freeze({
  modelAvatar: makeTestId(),
  avatar: makeTestId('avatar'),
  upload: makeTestId('upload'),
  uploadLink: makeTestId('upload-link'),
  uploadTooltipTitle: makeTestId('upload-tooltip-title'),
  uploadTooltipContent: makeTestId('upload-tooltip-content'),
  uploadTooltipLink: makeTestId('upload-tooltip-link'),
  pending: makeTestId('pending'),
  pendingLink: makeTestId('pending-link'),
  pendingTooltipTitle: makeTestId('pending-tooltip-title'),
  pendingTooltipContent: makeTestId('pending-tooltip-content'),
  pendingTooltipLink: makeTestId('pending-tooltip-link'),
  approved: makeTestId('approved'),
  uncropped: makeTestId('uncropped'),
  uncroppedLink: makeTestId('uncropped-link'),
  uncroppedTooltipTitle: makeTestId('uncropped-tooltip-title'),
  uncroppedTooltipContent: makeTestId('uncropped-tooltip-content'),
  uncroppedTooltipLink: makeTestId('uncropped-tooltip-link'),
});

export { testId };
