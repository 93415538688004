import { defineTracking } from 'services/google-analytics';

const category = 'navigation menu';

export default {
  group: {
    ...defineTracking({
      toggle: {
        category,
        action: 'toggle group',
        label: '{status} group',
        description:
          'This event triggered when user clicks on the navigation group header. ' +
          '{status} represents its status, it can be either "open" or "close"',
      },
    }),
  },
  item: {
    ...defineTracking({
      click: {
        category,
        action: 'open page',
        label: '{href}',
        description:
          'This event triggered when user toggles header notification. ' +
          '{href} represents the web address from the menu item clicked.',
      },
    }),
  },
};
