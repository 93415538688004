import React from 'react';
import toArray from 'utils/to-array';
import { useIntl } from 'react-intl';
import Html from 'components/html';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';
import parse from 'utils/parse';
import type { PerformerStatus } from 'store/performer';

import messages from './status.i18n';
import { testId } from './status.settings';
import styles from './status.module.scss';

interface StatusElement extends StyledElement<HTMLDivElement>, AriaAttributes, TestAutomation {
  value: PerformerStatus;
}

const Status: React.FunctionComponent<StatusElement> = (props) => {
  const { className, style, value, ...rest } = props;
  const { formatMessage } = useIntl();

  // TODO: figure out these two cases
  const newValue = React.useMemo(() => {
    if (value === 'new') {
      return 'missing-data';
    }

    if (value === 'suspended') {
      return 'suspending';
    }

    return value;
  }, [value]);

  return (
    <Html.div
      testId={testId.status}
      className={[styles.status, styles[parse.toCamelCase(newValue)], ...toArray(className)]}
      style={style}
      arias={rest}
    >
      {formatMessage(
        messages.pages.layout.topProfile.status[
          parse.toCamelCase(newValue) as keyof typeof messages.pages.layout.topProfile.status
        ]
      )}
    </Html.div>
  );
};

export type { StatusElement };
export { Status };
export default Status;
