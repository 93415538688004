import React from 'react';

import Header from './header';
import Footer from './footer';
import Container from './container';
import Toast from './toast';
import CookieConsent from './cookie-consent';
import ActivityIndicator from './activity-indicator';

interface ApplicationLayout {
  children: React.ReactNode;
}

const Layout: React.FunctionComponent<ApplicationLayout> = (props) => {
  const { children } = props;

  return (
    <React.Fragment>
      <ActivityIndicator />
      <Header />
      <Container>{children}</Container>
      <Toast />
      <Footer />
      <CookieConsent />
    </React.Fragment>
  );
};

export default React.memo(Layout);
